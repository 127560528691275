/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */

import isEmpty from 'validator/lib/isEmpty';
import camelCase from 'lodash/camelCase';
import { formatDistanceToNow } from 'date-fns';
import { Browser } from 'leaflet';

import { INTERNAL_ROLES, ROLES } from './constants';
import uploadFile from './uploadFile';

export const isInternal = role => {
  return INTERNAL_ROLES.includes(role);
};

export const isAdmin = role => {
  return role === 'admin';
};

export const isAdminOrCompanyAdmin = role => {
  return INTERNAL_ROLES.includes(role) || role === 'user_admin';
};

export const isExternalUser = role => {
  return role === 'user' || role === 'user_admin';
};

export const isUser = role => {
  return role === 'user';
};

export const isSupport = role => {
  return role === 'support';
};

export const isContractor = role => {
  return role === 'contractor';
};

export const determineIfNegative = num => {
  if (num < 0) {
    return true;
  }
  return false;
};

export const roundTwoDecimals = num => {
  return Math.round(num * 100) / 100;
};

export const checkBetweenNums = (
  numToCheck,
  minNum,
  maxNum,
  inclusive = false
) => {
  const min = Math.min(minNum, maxNum);
  const max = Math.max(minNum, maxNum);

  return inclusive
    ? numToCheck >= min && numToCheck <= max
    : numToCheck > min && numToCheck < max;
};

export const getLastMonth = () => {
  const today = new Date();
  return {
    firstDay: new Date(today.getFullYear(), today.getMonth() - 1, 1),
    lastDay: new Date(
      today.getFullYear(),
      today.getMonth(),
      0, // day: the zeroth day of this month is the last day of last month
      23, // hour: 11 PM
      59, // minute: 59
      59, // second: 59
      999 // mseconds: 999
    )
  };
};

export const formatDateRelative = date => {
  const dateObject = new Date(date);
  return formatDistanceToNow(dateObject, {
    addSuffix: true
  });
};

export const formatDateCreated = date => {
  const dateObject = new Date(date);
  return dateObject.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};

export const formatDateExact = (date, showTime = true) => {
  const dateObject = new Date(date);
  const dateString = dateObject.toLocaleDateString(undefined, {
    month: 'short',
    day: 'numeric'
  });
  const timeString = dateObject.toLocaleTimeString(undefined, {
    timeStyle: 'short'
  });
  if (showTime) {
    return `${dateString}, ${timeString}`;
  } else {
    return `${dateString}`;
  }
};

export const parseTextLinks = (messageText, linkColor) => {
  const urlRegex =
    /[(http(s)?)://(www.)?a-zA-Z0-9@:%._\-+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
  const replaceText = url => {
    return `<a href="${url}" style='color:${linkColor}' target="_blank" rel="noreferrer">${url}</a>`;
  };
  const replacedMessage = messageText.replace(urlRegex, replaceText);
  return replacedMessage;
};

export const createGradient = (color1, color2) => {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
};

export function setTokens(accessToken, refreshToken = '') {
  localStorage.setItem('accessToken', accessToken);
  if (refreshToken !== '') {
    localStorage.setItem('refreshToken', refreshToken);
  }
}

export function getTokens() {
  const accessTokenString = localStorage.getItem('accessToken');
  const refreshTokenString = localStorage.getItem('refreshToken');
  return { accessToken: accessTokenString, refreshToken: refreshTokenString };
}

export const stringCapitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const stringTruncate = (string, maxLength = 80) => {
  if (typeof string !== 'string') return '';
  return string.length > maxLength
    ? `${string.slice(0, maxLength - 6)}...`
    : string;
};

export const convertToCamelCase = item => {
  let converted = {};
  Object.keys(item).forEach(key => {
    const newKeyName = camelCase(key);
    converted = { ...converted, [newKeyName]: item[key] };
  });
  return converted;
};

// Sort an array of objects by the specified property name within the objects
export const sortArrayByProperty = property => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getCompanyName = user => {
  if (user && Object.keys(user).length > 0) {
    const { companies } = user;
    if (companies.length === 1) {
      return `${companies[0].name}`;
    }
  }
  return '';
};

export const getUserRole = user => {
  if (user && Object.keys(user).length > 0) {
    return ROLES.find(r => r.value === user.role).name;
  }
  return 'User';
};

export const getFormattedName = (
  user,
  firstNameOnly = false,
  emptyNameText = '[No Name]'
) => {
  if (user && Object.keys(user).length > 0) {
    const { firstName, lastName, email } = user;
    if (!firstName && !lastName) {
      return emptyNameText;
    }
    if (isEmpty(firstName) && isEmpty(lastName)) {
      return email;
    }
    return firstNameOnly
      ? `${stringCapitalize(firstName)}`
      : `${stringCapitalize(firstName)} ${stringCapitalize(lastName)}`;
  }
  return 'User';
};

export const getUserInitials = user => {
  if (user && Object.keys(user).length > 0) {
    const { firstName, lastName, email } = user;
    if (isEmpty(firstName) && isEmpty(lastName)) {
      return email.charAt(0).toUpperCase();
    }
    return `${isEmpty(firstName) ? '' : firstName.charAt(0).toUpperCase()}${
      isEmpty(lastName) ? '' : lastName.charAt(0).toUpperCase()
    }`;
  }
  return 'RA';
};

export const attachmentIsPDF = url => {
  return url ? url.substr(url.length - 3) === 'pdf' : true;
};

export const getImageContentLinks = image => {
  if (image.content?.filetypes) {
    const webpLinks = image.content.filetypes.webp;
    if (Object.keys(webpLinks).length === 0) {
      // image is too small to have webp links created, return original url (only will be the case with super tiny images below 200px)
      return { thumb: image.content.url, fullSize: image.content.url };
    }

    // get either 400px link or 200px, if 200px is the biggest it has
    const imageThumbnail = Object.keys(webpLinks).includes('400')
      ? webpLinks['400']
      : webpLinks['200'];
    const imageFullSize =
      Object.values(webpLinks)[Object.values(webpLinks).length - 1];

    return { thumb: imageThumbnail, fullSize: imageFullSize };
  } else {
    return {
      thumb: image.content ? image.content.url : image.file,
      fullSize: image.content ? image.content.url : image.file
    };
  }
};

export const parseGoogleDriveLink = url => {
  const splitPath = url.pathname.split('/');
  let linkId = '';
  if (splitPath[1] === 'folderview' && url.searchParams.has('id')) {
    linkId = url.searchParams.get('id');
  } else if (
    url.pathname.includes('/drive/folders/') ||
    url.pathname.includes('/file/d/')
  ) {
    linkId = splitPath[3];
  } else if (url.pathname.includes('/drive/u/0/folders/')) {
    linkId = splitPath[5];
  }
  return linkId;
};

export const getDetails = async placeId => {
  const placeDetails = {
    streetAddress: '',
    zipCode: '',
    city: '',
    stateName: '',
    stateAbbreviation: '',
    latLng: null
  };
  try {
    const response = await new window.google.maps.Geocoder().geocode({
      placeId
    });
    const streetAddress = response.results[0].formatted_address.split(',')[0];
    const zipCode = response.results[0].address_components.find(component =>
      component.types.includes('postal_code')
    );
    const state = response.results[0].address_components.find(component =>
      component.types.includes('administrative_area_level_1')
    );
    const city = response.results[0].address_components.find(component =>
      component.types.includes('locality')
    );
    if (zipCode) {
      placeDetails.zipCode = zipCode.long_name;
    }
    if (state) {
      placeDetails.stateName = state.long_name;
      placeDetails.stateAbbreviation = state.short_name;
    }
    if (city) {
      placeDetails.city = city.long_name;
    }
    placeDetails.streetAddress = streetAddress;
    placeDetails.latLng = response.results[0].geometry.location;
  } catch (e) {
    console.warn(e);
  }
  return placeDetails;
};

export const calculateArrayIsValid = (
  roof_pitch,
  num_panels,
  dist_to_eave,
  orientation,
  beam_spans,
  roof_overhang,
  module_type
) => {
  const moduleTypeLength = module_type ? module_type.length : 1907;
  const moduleTypeWidth = module_type ? module_type.width : 1054;
  const panelHeight =
    orientation === 'Portrait'
      ? moduleTypeLength / 25.4
      : moduleTypeWidth / 25.4;
  let sum = roof_overhang;
  beam_spans.forEach(beam_span => {
    sum += parseFloat(beam_span);
  });

  // calculate length of available roof space (hypotenuse) based on beam span + roof overhang
  const roofDiagonal = sum / Math.cos(roof_pitch * (Math.PI / 180));

  // compare available roof space to length of panels + the distance to eave
  const isValid =
    roofDiagonal >= parseInt(num_panels) * panelHeight + dist_to_eave;

  return isValid;
};

export const getFileExtension = file => {
  const extension = file.name?.toUpperCase().split('.').pop();
  return extension;
};

export const getFileName = fileName => {
  const name = fileName.substring(0, fileName.lastIndexOf('.'));
  return name;
};

export const getFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  let newBytes = bytes;
  do {
    newBytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(newBytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${newBytes.toFixed(dp)} ${units[u]}`;
};

export const formatHourMinuteTimer = totalSeconds => {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  if (hours >= 8) {
    const days = Math.floor(hours / 8);
    return `${days} day${days > 1 ? 's' : ''} ${hours % 8} hr${
      hours % 8 > 1 ? 's' : ''
    }`;
  } else {
    return `${hours} hr ${minutes} min`;
  }
};

export const jobIsOverWaitTime = jobData => {
  const {
    needs_cad_packet,
    pending_admin_total,
    pending_since_adjusted,
    created_by,
    status,
    pending_admin
  } = jobData;
  if (
    ['admin', 'support'].includes(created_by?.role) ||
    ['Approved', 'Cancelled'].includes(status) ||
    !pending_admin
  ) {
    return false;
  }
  const totalSeconds = pending_admin_total + pending_since_adjusted;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  return needs_cad_packet ? hours > 16 : hours > 8;
};

export const getMapsLink = (streetAddress, city, state) => {
  const baseUrl = 'https://www.google.com/maps/search/?api=1';
  const formattedAddress =
    !city && !state
      ? streetAddress
      : `${streetAddress}, ${city}, ${state?.name}`;
  return `${baseUrl}&query=${encodeURIComponent(formattedAddress)}`;
};

export const sleep = delay =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise(resolve => setTimeout(resolve, delay));

export const uploadNewRequirementFiles = async (
  requirements,
  eventCallbacks
) => {
  const newRequirements = { ...requirements };
  // eslint-disable-next-line no-unused-vars
  const placeholder = await Promise.all(
    Object.keys(requirements).map(async reqKey => {
      if (requirements[reqKey] instanceof File) {
        sleep(500);
        const fileUrl = await uploadFile({
          url: 'requirements/file',
          file: requirements[reqKey],
          events: eventCallbacks
        });
        newRequirements[reqKey] = fileUrl;
      } else if (typeof requirements[reqKey] === 'object') {
        const newObjectField = await uploadNewRequirementFiles(
          requirements[reqKey],
          eventCallbacks
        );
        newRequirements[reqKey] = newObjectField;
      }
    })
  );
  return newRequirements;
};

export const getCurrentMonthHours = (prevShiftHours, currentShiftStart) => {
  let currentShiftHours = 0.0;
  if (currentShiftStart) {
    currentShiftHours = (Date.now() - currentShiftStart) / 1000 / (60 * 60);
  }
  return prevShiftHours + currentShiftHours;
};

export const fetchFile = async (url, setFileFunction) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const blob = await response.blob();
    const file = new File([blob], url.split('/').pop(), {
      type: 'application/pdf'
    });
    await setFileFunction(file);
  } catch (error) {
    console.error('Error fetching file:', error);
  }
};

export const handleBase64 = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result.split(',')[1]);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const roundToNearest = (num, nearest) => {
  const factor = 1 / nearest;
  const roundedValue = Math.round(num * factor) / factor;

  const decimals = nearest.toString().split('.')[1]?.length || 0;

  return Number(roundedValue.toFixed(decimals));
};

export const getColorsArrayFromTheme = theme => {
  return [
    theme.palette.primary.main,
    theme.palette.secondary.darker,
    theme.palette.darkBlue.main,
    theme.palette.purple.main,
    theme.palette.pink.main,
    theme.palette.darkBlue.light,
    theme.palette.lightPink.main,
    theme.palette.purple.dark,
    theme.palette.darkPink.main,
    theme.palette.info.main,
    theme.palette.warning.lighter,
    theme.palette.darkPink.dark
  ];
};

export const getFilteredFrameTypes = (frameTypes, jobData) => {
  return jobData?.structure_type?.name === 'Manufactured Home'
    ? frameTypes
        .filter(item => item?.name === 'Pre-Manufactured Truss (2x2)')
        .map(item => item?.name)
        .sort()
    : frameTypes
        .filter(
          item =>
            item?.name !== 'Custom' &&
            item?.name !== 'Pre-Manufactured Truss (2x2)'
        )
        .map(item => item?.name)
        .sort((a, b) => b.localeCompare(a));
};

export const isMac = () => Browser.mac || Browser.userAgentContains('mac');

/**
 *
 * @param {KeyboardEvent} e
 * @returns {boolean}
 */
export const isCmdCtrlKey = e => (isMac() ? e.metaKey : e.ctrlKey);

export const isEmptyArray = array => Array.isArray(array) && array.length === 0;
